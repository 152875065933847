import http from '@/utils/http';

/**
 * 代理商管理
 */
export default {
  getList: {
    p: 'post,/manage/item/getlist',
    r: (data) => http({ url: '/manage/item/info/total', method: 'post', data })
  },
  update: {
    p: 'post,/manage/item/update',
    r: (data) => http({ url: '/manage/item/update', method: 'post', data })
  },
  add: {
    p: 'post,/manage/item/add',
    r: (data) => http({ url: '/manage/item/add', method: 'post', data })
  },
  del: {
    p: 'post,/manage/item/delete',
    r: (data) => http({ url: '/manage/item/delete', method: 'post', data })
  }
};
