import dataApi from '@/api/manage/statistics.js';

export default {
  data () {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请选择日期'));
      } else {
        callback();
      }
    };
    return {
      form: {},
      option: {
        submitText: '搜索',
        column: [{
          label: '开始日期',
          prop: 'startdate',
          type: 'date',
          valueFormat: 'yyyy-MM-dd',
          rules: [{ validator: validatePass, trigger: 'blur' }]
        }, {
          label: '结束日期',
          prop: 'enddate',
          type: 'date',
          valueFormat: 'yyyy-MM-dd',
          rules: [{ validator: validatePass, trigger: 'blur' }]
        }]
      },
      tableData: []
      // onLoad: () => {
      //   console.log('555');
      //   this.getList();
      // }
    };
  },
  computed: {},
  mounted () {
    console.log('---------------------------------8');
    this.getlist();
  },
  filters: {},
  methods: {
    getlist () {
      dataApi.getList.r().then((e) => {
        console.log(e, '执行了');
        this.tableData = e.data;
        done();
      }).catch((err) => {
        console.log(err);
      });
    },
    submit (form, done) {
      console.log(form);
      dataApi.getList.r(form).then((e) => {
        console.log(e, '又执行了');
        this.tableData = e.data;
        done();
      }).catch((err) => {
        console.log(err);
      });
    }
  }
};
