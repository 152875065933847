<template>
  <div :class="{ 'page-class': !$store.getters.tableConfig.card }">
    <avue-crud :data="tableData"
               :option="tbleOption"
               @row-save="handleSubmit"
               @row-update="rowUpdate"
               @refresh-change="getList"
               @row-del="rowDel"
               @on-load="onLoad"
               @search-change="searchChange"
               @search-reset="searchReset"
               :page.sync="page" v-model="itemData" ref="crud">
      <template slot-scope="{type,size,row,index}"
                slot="menu">
                 <el-button
                     icon="el-icon-check"
                     :size="size"
                   :type="type"
                     @click.stop="rowEdit(row,index)">编辑</el-button>
        <el-button icon="el-icon-view"
                   :size="size"
                   :type="type"
                   @click.stop="userView(row,index)">员工</el-button>
      </template>
      <template slot-scope="{disabled}" slot="link_memberForm">
        <el-select
          v-model="itemData.link_member"
          filterable
          remote
          multiple
          placeholder="请输入会员名称"
          :disabled="disabled"
          @visible-change="linkMemberChangeSelect"
          :remote-method="getMemberList">
          <el-option
            v-for="item in memberList"
            :key="item.id"
            :label="item.nickname"
            :value="item.id">
          </el-option>
        </el-select>
      </template>
    </avue-crud>
    <el-drawer
      title="员工管理"
      :before-close="drawerUserClose"
      :visible.sync="drawerUserVisible"
      direction="ttb"
      size="100%"
    >
      <div class="details">
        <avue-crud
          :data="userData"
          :option="userOption"
          @row-save="handleUserSubmit"
          @row-update="rowUserUpdate"
          @row-del="rowUserDel"
          @refresh-change="getUserList"
        >
          <template slot="menu" scope="{row}">
            <el-button @click="onLinkMember(row)" icon="el-icon-user" size="small" type="text">关联会员</el-button>
            <el-button @click="editPassword(row)" icon="el-icon-check" size="small" type="text">修改密码</el-button>
          </template>
        </avue-crud>
      </div>
    </el-drawer>
    <el-dialog
      title="关联会员"
      :visible.sync="dialogLinkVisible"
      width="40%">
        <avue-form
            ref="upLinkForm"
            :option="formLinkOp"
            v-model="formLinkDa"
            @submit="submitUpLinkForm">
            <template slot-scope="{disabled}" slot="link_member">
              <el-select
                v-model="formLinkDa.link_member"
                filterable
                multiple
                placeholder="请输入会员名称"
                :disabled="disabled"
                @visible-change="linkMemberChangeSelect"
                :remote-method="getMemberList">
                <el-option
                  v-for="item in memberList"
                  :key="item.id"
                  :label="item.nickname"
                  :value="item.id">
                </el-option>
              </el-select>
            </template>
        </avue-form>
    </el-dialog>
     <el-dialog
      title="修改密码"
      :visible.sync="dialogPwdVisible"
      width="40%">
        <avue-form
            ref="upPwdForm"
            :option="formOp"
            v-model="formDa"
            @submit="submitUpPwdForm">
            <template slot="menuForm">
                <el-button @click="reset" slot="reference" icon="el-icon-refresh-right" type="warning">直接重置</el-button>
            </template>
        </avue-form>
    </el-dialog>
  </div>
</template>
<script>
import List from './list';

export default List;
</script>
