<template>
  <div class="box">
    <div>
      <avue-form v-model="form"
                 :option="option"
                 @submit="submit">
      </avue-form>
    </div>
    <div>
      <el-table :data="tableData"
                style="width: 100%"
                :show-summary='true'
                :border="true">
        <el-table-column fixed
                         prop="title"
                         label="商户名称"
                         width="200">
        </el-table-column>
         <el-table-column fixed
                         prop="member_number"
                         label="新增会员数"
                         align="center"
                         width="100">
        </el-table-column>
        <el-table-column prop="guestinto_total"
                         label="进件数"
                          align="center"
                         width="100">
        </el-table-column>
        <el-table-column prop="trading_total"
                         label="交易金额(万元)"
                          align="right"
                         width="200">
        </el-table-column>
        <el-table-column prop="rebate_total"
                         label="进件佣金(元)"
                          align="right"
                         width="200">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import List from './list';

export default List;
</script>

<style>
.box > div {
  background-color: white;
  padding: 20px;
  margin-bottom: 10px;
}
</style>
