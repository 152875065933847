import http from '@/utils/http';

/**
 * 代理商管理
 */
export default {
  getList: {
    p: 'post,/manage/item/getlist',
    r: (data) => http({ url: '/manage/item/getlist', method: 'post', data })
  },
  update: {
    p: 'post,/manage/item/update',
    r: (data) => http({ url: '/manage/item/update', method: 'post', data })
  },
  add: {
    p: 'post,/manage/item/add',
    r: (data) => http({ url: '/manage/item/add', method: 'post', data })
  },
  del: {
    p: 'post,/manage/item/delete',
    r: (data) => http({ url: '/manage/item/delete', method: 'post', data })
  },
  getOne: {
    p: 'post,/manage/item/getone',
    r: (data) => http({ url: '/manage/item/getone', method: 'post', data })
  },
  userAdd: {
    p: 'post,/manage/item/user/add',
    r: (data) => http({ url: '/manage/item/user/add', method: 'post', data })
  },
  userUpdate: {
    p: 'post,/manage/item/user/update',
    r: (data) => http({ url: '/manage/item/user/update', method: 'post', data })
  },
  userDel: {
    p: 'post,/system/user/delete',
    r: (data) => http({ url: '/system/user/delete', method: 'post', data })
  },
  getUserList: {
    p: 'post,/manage/item/user/list',
    r: (data) => http({ url: '/manage/item/user/list', method: 'post', data })
  },
  getLinkMember: {
    p: 'post,/manage/item/link/member/list',
    r: (data) => http({ url: '/manage/item/link/member/list', method: 'post', data })
  },
  getChild: {
    p: 'post,/manage/item/child',
    r: (data) => http({ url: '/manage/item/child', method: 'post', data })
  },
  updatePass: {
    p: 'post,/system/user/update_password',
    r: (data) => http({ url: '/system/user/update_password', method: 'post', data })
  },
  exportFile: {
    p: 'post,/manage/item/export',
    r: (data) => http({ url: '/manage/item/export', method: 'post', data })
  }
};
