import './list.scss';
import dataApi from '@/api/manage/item.js';
import dataMmeberApi from '@/api/manage/member.js';
import dataUserApi from '@/api/system/account.js';

export default {
  name: 'item-list',
  components: {},

  data () {
    const phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    const validatePhone = (rule, value, callback) => {
      console.log(rule);
      if (!value) {
        return callback(new Error('号码不能为空!!'));
      }
      setTimeout(() => {
        if (!phoneReg.test(value)) {
          callback(new Error('请输入正确的手机号码'));
        } else {
          callback();
        }
      }, 1000);
    };
    const validatePass = (rule, value, callback) => {
      console.log(rule);
      // console.log(value, this.form.newPassword);
      if (!value) {
        return callback(new Error('重复密码不能为空!!'));
      }
      setTimeout(() => {
        if (value !== this.form.password) {
          callback(new Error('两次密码不一样！！'));
        } else {
          callback();
        }
      }, 1000);
    };
    return {
      page: {
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 100]
      },
      drawerUserVisible: false,
      dialogLinkVisible: false,
      dialogPwdVisible: false,
      itemData: {},
      account: {},
      tableData: [],
      tbleOption: {
        total: 10,
        align: 'center',
        cellBtn: false,
        cancelBtn: false,
        editBtn: false,
        ...this.$store.getters.tableConfig,
        column: [
          {
            label: '代理商',
            prop: 'title',
            type: 'input',
            search: 'true',
            required: true,
            span: 24,
            rules: [
              {
                required: true,
                message: '名称不能为空'
              }
            ]
          },
          {
            label: '会员数',
            prop: 'member_number'
          },
          {
            // 关联会员
            label: '关联会员',
            prop: 'link_member',
            search: 'true',
            required: true,
            hide: true,
            span: 24,
            formslot: true,
            rules: [
              {
                required: true,
                message: '关联会员不能为空'
              }
            ],
            type: 'select'
          },
          {
            type: 'radio',
            label: '状态',
            value: 1,
            dicData: [
              {
                label: '禁用',
                value: 0
              },
              {
                label: '启用',
                value: 1
              }
            ],
            display: true,
            search: 'true',
            prop: 'status',
            rules: [
              {
                required: true,
                message: '请选择状态'
              }
            ],
            required: true,
            span: 12
          },
          {
            label: '说明',
            prop: 'remark',
            type: 'textarea',
            span: 24
          },
          {
            // 创建者 create_by
            label: '创建者',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_user'
          },
          {
            // 创建时间 create_time
            label: '创建时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_time'
          },
          {
            // 更新者 update_by
            label: '更新者',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_user'
          },
          {
            // 更新时间 update_time
            label: '更新时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_time'
          }
        ]
      },
      searchData: {},
      userOption: {
        total: 50,
        align: 'center',
        ...this.$store.getters.tableConfig,
        card: false,
        menu: true,
        addBtn: true,
        filterBtn: false,
        selection: false,
        column: [
          {
            // 用户ID   user_id
            label: '用户ID',
            addDisplay: false,
            editDisplay: false,
            hide: true,
            prop: 'user_id'
          },
          {
            // 昵称  nick_name
            label: '昵称 ',
            span: 12,
            rules: [
              {
                required: true,
                message: '请输入昵称',
                trigger: 'blur'
              }
            ],
            prop: 'nick_name'
          },
          {
            label: '账号',
            searchRange: true,
            searchSpan: 5,
            searchLabelWidth: 50,
            search: true,
            span: 12,
            placeholder: '用户名/昵称/手机号/邮箱',
            tip: '账号最多30字符',
            editDisplay: false,
            editDisabled: true,
            maxlength: 30,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: '请输入账号',
                trigger: 'blur'
              }
            ],
            prop: 'user_name'
          },
          {
            // 手机号码  mobile
            label: '手机号码',
            hide: true,
            rules: [{ required: true, validator: validatePhone, trigger: ['blur', 'change'] }],
            prop: 'mobile'
          },
          {
            label: '关联角色',
            type: 'tree',
            span: 12,
            display: true,
            dicFlag: true,
            prop: 'role_id',
            props: {
              label: 'label',
              value: 'value'
            },
            multiple: true,
            dicUrl: `${this.$baseUrl}general/get_user_role`,
            dicQuery: {},
            dicMethod: 'post',
            dataType: 'number',
            bind: 'role.idx',
            rules: [
              {
                required: true,
                message: '请选择角色',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '状态',
            span: 12,
            rules: [
              {
                required: true,
                message: '请选择用户状态',
                trigger: 'blur'
              }
            ],
            type: 'radio',
            value: 1,
            dicData: [
              {
                label: '正常',
                value: 1
              },
              {
                label: '停用',
                value: 0
              }
            ],
            prop: 'status'
          },
          {
            label: '类型',
            span: 12,
            rules: [
              {
                required: true,
                message: '请输入类型',
                trigger: 'blur'
              }
            ],
            prop: 'user_type',
            type: 'radio',
            value: 'mpc',
            dicData: [
              {
                label: '系统用户',
                value: 'system'
              },
              {
                label: '商户员工',
                value: 'mpc'
              }
            ]
          },
          {
            // 查询时间状态（2登陆时间 1 添加时间）
            label: '日期',
            searchRange: true,
            search: true,
            hide: true,
            searchSpan: 5,
            // searchLabelWidth: 30,
            addDisplay: false,
            editDisplay: false,
            span: 12,
            labelWidth: 0,
            type: 'select',
            value: 1,
            dicData: [
              {
                label: '添加时间',
                value: 1
              },
              {
                label: '登陆时间',
                value: 2
              }
            ],
            prop: 'date_type'
          },
          {
            // 登录时间 date_time
            // label: '时间',
            labelWidth: 1,
            searchSpan: 5,
            searchLabelWidth: 1,
            type: 'daterange',
            valueFormat: 'yyyy-MM-dd',
            searchRange: true,
            search: true,
            addDisplay: false,
            hide: true,
            editDisplay: false,
            prop: 'date_time'
          },
          {
            // 最后登录IP  login_ip
            label: '最后登录IP',
            addDisplay: false,
            editDisplay: false,
            prop: 'login_ip'
          },
          {
            // 最后登录时间 login_date
            label: '登录时间',
            type: 'date',
            addDisplay: false,
            editDisplay: false,
            prop: 'login_date'
          },
          {
            // 创建者 create_by
            label: '创建者',
            addDisplay: false,
            hide: true,
            editDisplay: false,
            prop: 'create_by'
          },
          {
            // 创建时间 create_time
            label: '创建时间',
            addDisplay: false,
            type: 'datetimerange',
            valueFormat: 'yyyy-MM-dd hh:mm:ss',
            searchRange: true,
            // search: true,
            hide: true,
            editDisplay: false,
            prop: 'create_time'
          },
          {
            // 更新者 update_by
            label: '更新者',
            addDisplay: false,
            hide: true,
            editDisplay: false,
            prop: 'update_by'
          },
          {
            // 备注 remark
            label: '备注',
            prop: 'remark',
            span: 24,
            type: 'textarea'
          }
        ]
      },
      userData: [],
      memberList: [],
      formDa: {},
      formOp: {
        column: [
          {
            label: '旧密码',
            prop: 'oldpassword',
            type: 'password',
            span: 24,
            rules: [{ required: true, message: '旧密码不能为空', trigger: 'blur' }]
          },
          {
            label: '新密码',
            prop: 'password',
            type: 'password',
            span: 24,
            rules: [{ required: true, message: '新密码不能为空', trigger: 'blur' }]
          },
          {
            label: '确认密码',
            prop: 'newPassword',
            type: 'password',
            span: 24,
            rules: [{ required: true, validator: validatePass, trigger: ['blur', 'change'] }]
          }
        ],
        labelPosition: 'left',
        labelSuffix: '：',
        labelWidth: 100,
        gutter: 0,
        menuSpan: 24,
        menuBtn: true,
        submitBtn: true,
        submitText: '提交',
        emptyBtn: true,
        emptyText: '清空',
        menuPosition: 'center'
      },
      formLinkDa: {},
      formLinkOp: {
        column: [
          {
            // 关联会员
            label: '关联会员',
            prop: 'link_member',
            search: 'true',
            required: true,
            hide: true,
            span: 24,
            formslot: true,
            rules: [
              {
                required: true,
                message: '关联会员不能为空'
              }
            ],
            type: 'select'
          }
        ],
        labelPosition: 'left',
        labelSuffix: '：',
        labelWidth: 100,
        gutter: 0,
        menuSpan: 24,
        menuBtn: true,
        submitBtn: true,
        submitText: '提交',
        emptyBtn: true,
        emptyText: '清空',
        menuPosition: 'center'
      }
    };
  },
  computed: {},
  mounted () {},
  filters: {},
  methods: {
    // 分页
    onLoad (page) {
      this.page = page;
      this.getList();
      this.getMemberList();
    },
    // 筛选
    searchChange (params, done) {
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset () {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    // 添加
    handleSubmit (form, done, loading) {
      console.log('form', form, loading);
      form.link_member = form.link_member.toString();
      dataApi.add.r(form).then(res => {
        console.log(res);
        done(form);
        this.getList();
        loading();
      });
      done();
    },
    // 删除
    rowDel (form) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('删除数据', form);
        dataApi.del.r({ id: form.id }).then(res => {
          console.log(res);
          this.getList();
          this.$message.success('删除成功');
        });
      });
    },
    rowEdit (row, index) {
      // 获取已关联的会员
      this.getLinkMember(row.id);
      console.log('index', row, index);
      this.$refs.crud.rowEdit(row, index);
    },
    // 修改
    rowUpdate (form, index, done, loading) {
      console.log('index', form, index);
      form.link_member = form.link_member.toString();
      dataApi.update.r(form).then(res => {
        console.log(res);
        done(form);
        this.getList();
        loading();
      });
      done();
    },
    getLinkMember (val) {
      dataApi.getLinkMember
        .r({
          item_id: val
        })
        .then(res => {
          this.memberList = res.data;
          console.log('mem', this.memberList);
        });
      console.log('memberlist', this.memberList);
    },
    // 获取
    getList () {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getList.r(postData).then(res => {
        console.log('接口返回', res);
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    },
    linkMemberChangeSelect () {},
    // 获取会员
    getMemberList (query) {
      let postData = {};
      if (query !== '') {
        postData = {
          nickname: query
        };
      }
      const that = this;
      dataMmeberApi.getList.r(postData).then(res => {
        that.memberList = res.data.list;
      });
    },
    // 获取已关联的会员集合
    onLinkMember (row) {
      console.log(row);
      this.account = row;
      this.formLinkDa = row;
      this.dialogLinkVisible = true;
    },
    // 获取员工
    getUserList () {
      const postData = {
        item_id: this.itemData.id
      };

      dataApi.getUserList.r(postData).then(res => {
        this.userData = res.data.list;
      });
    },
    drawerUserClose () {
      this.drawerUserVisible = false;
    },
    userView (item) {
      this.itemData = item;
      this.getUserList();
      this.getLinkMember(item.id);
      this.drawerUserVisible = true;
    },
    // 新增员工，编辑员工
    // 添加
    handleUserSubmit (form, done, loading) {
      form.item_id = this.itemData.id;
      dataUserApi.add.r(form).then(res => {
        console.log(res);
        done(form);
        this.getUserList();
        loading();
      });
      done();
    },
    // 删除
    rowUserDel (form) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('删除数据', form);
        dataApi.userDel.r({ user_id: form.user_id }).then(res => {
          console.log(res);
          this.getUserList();
          this.$message.success('删除成功');
        });
      });
    },
    // 修改
    rowUserUpdate (form, index, done, loading) {
      console.log('index', form, index);
      dataUserApi.update.r(form).then(res => {
        console.log(res);
        done(form);
        this.getUserList();
        loading();
      });
      done();
    },
    // 重置密码
    reset () {
      const postData = {
        user_id: this.account.user_id,
        type: 2
      };
      dataUserApi.updatePass.r(postData).then(res => {
        console.log('res', res);
        this.$message.success('密码重置已被重置为: 123456');
        this.dialogPwdVisible = false;
      });
    },
    // 修改密码
    editPassword (row) {
      console.log('row', row);
      this.account = row;
      this.dialogPwdVisible = true;
    },
    // 修改密码提交
    submitUpPwdForm (e, done) {
      const postData = {
        user_id: this.account.user_id,
        ...e,
        type: 1
      };
      dataApi.updatePass.r(postData).then(res => {
        console.log('res', res);
        done();
        this.$message.success('密码修改成功');
        this.dialogPwdVisible = false;
      });
      setTimeout(() => {
        done();
      }, 1000);
    },
    // 修改密码提交
    submitUpLinkForm (e, done) {
      e.link_member = e.link_member.toString();
      const postData = {
        user_id: this.account.user_id,
        item_id: this.itemData.id,
        ...e
      };
      dataApi.userUpdate.r(postData).then(res => {
        console.log('res', res);
        done();
        this.$message.success('关联会员修改成功');
        this.dialogLinkVisible = false;
      });
      setTimeout(() => {
        done();
      }, 1000);
    }
  }
};
